













import Vue from "vue";
import AuthService, { SignInData } from "@/services/authService";
import store from "@/store";
import { User } from "@/models/user";
import { TokenData } from "@/models/token";
import AuthTemplate from "@/components/templates/Auth.vue";

export default Vue.extend({
  name: "SignIn",
  components: { AuthTemplate },
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      loading: false,
      multiFactor: false,
      code: "",
    };
  },
  methods: {
    async onSubmit(): Promise<void> {
      this.loading = true;
      try {
        const data = {
          email: this.email,
          password: this.password,
        } as SignInData;
        if (this.multiFactor) {
          data.mfaCode = this.code;
        }
        const tokenData: TokenData = await AuthService.signIn(data);
        AuthService.setTokenData(tokenData);
        const User: User = await AuthService.getUser();
        await store.dispatch("session/login", {
          user: User,
          tokenData: tokenData,
        });
        this.$notification.success(this.$t("signIn.success"));
        await this.$router.push("/dashboard");
      } catch (e) {
        if (AuthService.multiFactorEnabled(e)) {
          this.multiFactor = true;
          (this.$refs.observer as Vue & { reset: () => boolean }).reset();
        } else {
          this.$sentry.capture(e, "SignIn", "onSubmit");
          this.$notification.error(e);
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
